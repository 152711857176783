import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/wyzwania-serwisu.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "karta-zlecenia-serwisowego.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    zdjecie2: file(relativePath: { eq: "panel-klienta-serwis.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "aplikacja-serwis.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Wyzwaniaserwisu = ({ data }) => {
  return (
    <Artykul
      title="7 problemów Twojego serwisu – jak je rozwiązać"
      keywords={["zlecenia cykliczne"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Jak skutecznie zarządzać zleceniami cyklicznymi?"
      metaTitle="Jak skutecznie zarządzać zleceniami cyklicznymi?"
      metaDescription="Jak skutecznie zarządzać zleceniami cyklicznymi? ✅ Jak automatycznie wysyłać klientom przypomnienia SMS o przeglądach? •  Obejrzyj video"
    >
      <p>
        {" "}
        Jak uporządkować pracę działu serwisu? Jak zoptymalizować niektóre
        procesy zachodzące w Twojej organizacji? Jak usprawnić komunikację z
        klientami, a także wewnątrz serwisu? Jak zrezygnować z papierowych
        protokołów serwisowych i zastąpić je wersją elektroniczną?{" "}
        <strong>
          Odpowiedzi na te pytania znajdziesz w kolejnym odcinku z serii
          „Zarządzanie serwisem online”.
        </strong>
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/BNmPHQuxvA0"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <h2>Wyzwania serwisu</h2>
      <p>
        Rozważając wdrożenie systemu do zarządzania zleceniami najpierw zastanów
        się, jakie problemy biznesowe ma on rozwiązać oraz jakich korzyści Twoja
        firma oczekuje po zakończeniu procesu wdrożenia. <br />
        Jakie problemy najczęściej wymieniają nasi potencjalni klienci?{" "}
      </p>
      <p>
        <ul>
          <li>
            <strong>Brak zintegrowanej bazy informacji</strong> o klientach,
            lokalizacjach, urządzaniach serwisowanych, czy zleceniach
            serwisowych{" "}
          </li>
          <li>
            <strong>Kłopotliwy sposób komunikacji</strong> wewnątrz serwisu
          </li>
          <li>
            <strong>Niespójna komunikacja</strong> z klientami i podwykonawcami
          </li>
          <li>
            <strong>Brak rozwiązania pozwalającego na monitorowanie</strong>{" "}
            pracy wykonywanej w terenie
          </li>
          <li>
            <strong>
              Wykorzystywanie w codziennej pracy przestarzałego oprogramowania
            </strong>
            , które nie spełnia potrzeb serwisu
          </li>
          <li>
            Firmowe dane przechowywane są w{" "}
            <strong>wielu różnych systemach i aplikacjach</strong>
          </li>
          <li>
            <strong>Opieranie dokumentacji zleceń na papierowych</strong>{" "}
            protokołach serwisowych
          </li>
        </ul>
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz rozwiązać problemy Twojego serwisu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać serwisem
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Jak je rozwiązać?</h2>
      <br />
      <p>
        Jeśli którykolwiek z elementów powyższej listy jest bliski Twojej firmie
        serwisowej to koniecznie czytaj dalej. W poniższych punktach wyjaśniamy
        jak nasze rozwiązania pomagają rozwiązać wszystkie wymienione wyżej
        problemy.{" "}
      </p>

      <ol>
        <p>
          <strong>
            {" "}
            <li>Zintegrowana karta kontrahenta</li>
          </strong>
          To miejsce, w którym znajdują się wszystkie informacje na temat danego
          klienta. Karta podzielona jest na kilka zakładek, tak, aby
          zachować maksymalny porządek i przejrzystość. Każdą informację można
          błyskawicznie odszukać. Zakładki przedstawiają pełen przekrój danych.
          Od podstawowych, takich jak nazwa firmy, adresy, NIP czy numery
          telefonów do osób kontaktowych po listę dokumentów handlowych.
          Wybierając odpowiednią zakładkę możesz natychmiast sprawdzić listę
          zleceń serwisowych, które realizujesz dla danego klienta. W podobny
          sposób przejdziesz do listy jego serwisowanych urządzeń.
        </p>
        <br />
        <p>
          {" "}
          <strong>
            <li>Baza urządzeń</li>
          </strong>
          W tym miejscu znajdziesz uporządkowany spis wszystkich serwisowanych
          przez Twoją firmę urządzeń. Każde z nich posiada swój indywidualny
          numer seryjny i przypisane jest do danego klienta. Baza może być
          wyświetlana w formie listy i filtrowana w wybrany sposób. Twoi
          serwisanci zawsze szybko odnajdą zlecenie powiązane z konkretnym
          urządzeniem i firmą.
        </p>
        <br />
        <p>
          {" "}
          <strong>
            <li>Zlecenia serwisowe w jednym miejscu</li>
          </strong>
          Nasze rozwiązanie pozwoli Twojemu serwisowi korzystać ze spójnej bazy
          wiedzy o zleceniach serwisowych. Kartę zlecenia można uzupełnić o dane
          takie jak nazwa klienta, kontakty, adresy czy urządzenia serwisowane
          powiązane ze zleceniem. Z poziomu karty natychmiast określisz typ
          zlecenia (np. serwis, przegląd, naprawa gwarancyjna), a także
          przypiszesz do niego osobę odpowiedzialną. Możliwości dopasowania do
          potrzeb są naprawdę duże, kartę można wzbogacić o dowolne pola, które
          są potrzebne Twojej firmie w codziennej pracy.
          <br />
          <Lightbox
            style={{
              maxWidth: 900,
              margin: "0 auto",
            }}
            images={[data.zdjecie1]}
            alts={["baza zleceń"]}
          />
          <br />
        </p>
        <p>
          {" "}
          <strong>
            <li>Tablica i kalendarz zleceń</li>
          </strong>
          Zarządzanie serwisem wymaga narzędzia, które pozwala w dowolnym
          momencie określić bieżący status wszelkich zleceń. Doskonałym sposobem
          na to jest duet tablicy Kanban z kalendarzem zleceń. To pierwsze
          przedstawia przepływ zleceń w graficzny sposób, pozwalając ograniczyć
          marnotrawstwo zasobów i ustalać priorytety pracy serwisantów. Tablica
          Kanban to także prosty sposób na monitorowanie i eliminację „wąskich
          gardeł” w poszczególnych etapach pracy nad zleceniami. Kalendarz
          zleceń pozwoli kierownikowi serwisu intuicyjnie zaplanować pracę
          każdego serwisanta, błyskawicznie weryfikując którzy z nich mają
          mniejsze obłożenie w danym dniu tygodnia.
        </p>
        <br />
        <p>
          {" "}
          <strong>
            <li>Panel dla klientów</li>
          </strong>
          Komunikacja z klientem to niezwykle ważny element pracy serwisu. Z
          panelem klienta w programie do serwisu nie musisz
          angażować pracowników firmy w kontakt z kontrahentami. Wdrożenie
          panelu pozwoli klientom samodzielnie dodawać zgłoszenia,
          sprawdzić status zlecenia czy zweryfikować regulamin Twoich usług.
          Chcesz przekazywać klientom więcej informacji? Żaden problem. Do
          panelu możesz dodać także listę faktur sprzedaży i powiązanych
          urządzeń. Twoi klienci na pewno docenią możliwość ustawienia
          automatycznych powiadomień o zmianie statusu zlecenia. Daj im znać, że
          reklamacja została rozpatrzona. Powiadom ich o zakończeniu naprawy.
          SMSem albo mailem.
          <Lightbox
            style={{
              maxWidth: 900,
              margin: "0 auto",
            }}
            images={[data.zdjecie2]}
            alts={["panel klienta"]}
          />
          <br />
        </p>
        <p>
          {" "}
          <strong>
            <li>Błyskawiczne protokoły serwisowe </li>
          </strong>
          Elektroniczne protokoły serwisowe wystawiane jednym kliknięciem są
          ogromne ułatwieniem dla firmy serwisowej. To czytelne dokumenty (z
          możliwością wygenerowania PDF) przedstawiające najważniejsze
          informacje wypełniane przez pracowników. Zawartość protokołów jest
          pełni elastyczna, możesz ustalić ich wygląd i rodzaj informacji.
          Serwisanci będą zadowoleni, bo nie muszą wypełniać ręcznie druków.
          Klienci też, bo wraz zakończeniem zlecenia natychmiast
          otrzymują podsumowanie z pełną informacją o jego przebiegu.
        </p>
        <br />
        <p>
          {" "}
          <strong>
            <li>Aplikacja mobilna</li>
          </strong>
          Twoi serwisanci wykonują pracę także zdalnie, u klientów? Skorzystaj z
          naszej serwisowej aplikacji na urządzenia z systemami Android i iOS.
          Usprawnia ona rejestrowanie informacji o zleceniach w terenie, a także
          umożliwia monitorowanie pracy serwisantów oraz ich lokalizacji na
          mapie. Świetnie może sprawdzić się też wysyłanie powiadomień o nowych
          zleceniach na urządzenie serwisanta. Aplikacja wskaże też adres
          kolejnego zlecenia i ułatwi nawigację do miejsca docelowego. Dzięki
          natychmiastowej aktualizacji statusów zlecenia kierownik monitorując
          tablicę zleceń w bazowym systemie do serwisu jest zawsze na bieżąco i
          nie musi dopytywać pracowników na jakim etapie pracy są w danym
          momencie.
          <Lightbox
            style={{
              maxWidth: 300,
              margin: "0 auto",
            }}
            images={[data.zdjecie3]}
            alts={["aplikacja serwisowa"]}
          />
          <br />
        </p>
      </ol>

      <br />
      <h2>Rozwiąż problemy swojego serwisu bez wysiłku</h2>
      <br />
      <p>
        Wybierz odpowiednie oprogramowanie dla swojego serwisu i staw czoła
        wszystkim wymienionym w artykule wyzwaniom. Zdecyduj się na rozwiązania
        serwisowe na miarę XXI wieku i z łatwością wyprzedź konkurencję.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz rozwiązać problemy Twojego serwisu?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać serwisem
            </Link>
          </div>
          </div>
    </section>
    </Artykul>
  );
};

export default Wyzwaniaserwisu;
